.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.header > .logo {
    max-width: 100%;
    max-height: 100%;
}
