.layout-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.layout-container > .layout-header {
    height: 10%;
}

.layout-container > .layout-main {
    height: 80%;
    display: flex;
    flex-direction: row;
}

.layout-container > .layout-footer {
    height: 10%;
}
