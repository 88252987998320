.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer-text {
    font-size: 1rem;
    font-weight: 600;
}
